<script>
import axios from "axios";
import userStore from '@/state/store'
import moment from "moment/moment";
import 'moment-timezone';
import {INQUIRY_KPI} from "@/core/KPI";

moment.tz.setDefault("Asia/Tashkent"); // Set default timezone once

export default {
  props: {
    status: {
      type: [String, null],
      required: false,
      default: () => null
    },
    start_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
    end_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      inquires: [],
      INQUIRY_KPI: INQUIRY_KPI
    }
  },
  computed: {
    user() {
      return userStore.state.user
    },
  },
  methods: {
    async getInquires() {
      try {
        let params = {
          manager_id: this.user.id,
        }

        if (this.start_date) {
          params.date_from = this.start_date
        }

        if (this.end_date) {
          params.date_to = this.end_date
        }

        let response = await axios.get('/inquiry/statistic/kpi/detail/', {
          params: params
        })

        this.inquires = response.data.results || []
        this.inquires = this.inquires.map(i => {
          let pending_days_detailed = this.splitDays(i.created, i.status === 'pending' ? new Date() : i.quoted_at)

          let total_pending_seconds = pending_days_detailed.reduce((a, b) => a + b.seconds, 0);
          let total_pending_minutes = pending_days_detailed.reduce((a, b) => a + b.minutes, 0);
          let total_pending_hours = pending_days_detailed.reduce((a, b) => a + b.hours, 0);

          total_pending_minutes += Math.floor(total_pending_seconds / 60);
          total_pending_seconds = total_pending_seconds % 60;

          // Normalize minutes to hours
          total_pending_hours += Math.floor(total_pending_minutes / 60);
          total_pending_minutes = total_pending_minutes % 60;

          const get_completion_days = () => {

            if (i.status === 'success') {

              let follow_up_days_detailed = this.splitDays(i.quoted_at, i.success_at)

              let total_completion_seconds = follow_up_days_detailed.reduce((a, b) => a + b.seconds, 0);
              let total_completion_minutes = follow_up_days_detailed.reduce((a, b) => a + b.minutes, 0);
              let total_completion_hours = follow_up_days_detailed.reduce((a, b) => a + b.hours, 0);

              total_completion_minutes += Math.floor(total_completion_seconds / 60);
              total_completion_seconds = total_completion_seconds % 60;

              // Normalize minutes to hours
              total_completion_hours += Math.floor(total_completion_minutes / 60);
              total_completion_minutes = total_completion_minutes % 60;


              return {
                items: follow_up_days_detailed,
                total_hours: total_completion_hours,
                total_minutes: total_completion_minutes,
                total_seconds: total_completion_seconds
              }

            } else if (i.status === 'fail') {
              let follow_up_days_detailed = this.splitDays(i.quoted_at, i.fail_at)

              let total_completion_seconds = follow_up_days_detailed.reduce((a, b) => a + b.seconds, 0);
              let total_completion_minutes = follow_up_days_detailed.reduce((a, b) => a + b.minutes, 0);
              let total_completion_hours = follow_up_days_detailed.reduce((a, b) => a + b.hours, 0);

              total_completion_minutes += Math.floor(total_completion_seconds / 60);
              total_completion_seconds = total_completion_seconds % 60;

              // Normalize minutes to hours
              total_completion_hours += Math.floor(total_completion_minutes / 60);
              total_completion_minutes = total_completion_minutes % 60;


              return {
                items: follow_up_days_detailed,
                total_hours: total_completion_hours,
                total_minutes: total_completion_minutes,
                total_seconds: total_completion_seconds
              }

            } else {
              return {
                items: [],
                total_hours: 0,
                total_minutes: 0,
                total_seconds: 0
              }
            }

          }

          let inquiry = {
            ...i,
            pending_days: {
              items: pending_days_detailed,
              total_pending_hours,
              total_pending_minutes,
              total_pending_seconds
            },
            completion_days: get_completion_days()
          }
          if (i.status === 'quoted') {
            inquiry.possibleCompletion = this.getInquiryPossibleCompletionStatus(i.quoted_at)
          }
          return inquiry
        })
      } catch {
        alert("error")
      }
    },
    cleanText(html) {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText;
    },
    formatDate(date) {
      return moment(date).format('lll')
    },
    durationInDays(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const totalHours = duration.asHours();

      let daysDescription;

      if (totalHours < 60) {
        daysDescription = "within 60 hours";
      } else {
        daysDescription = `after ${totalHours.toFixed(0)} hours`;
      }

      return daysDescription
    },
    timeAgo(startDate) {
      const date = moment(startDate); // Get the current time
      return moment(date).fromNow();
    },
    formatDuration(durationInSeconds) {
      const duration = moment.duration(durationInSeconds, 'seconds');

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      let formattedDuration = '';

      if (days > 0) {
        formattedDuration += `${days} days `;
      }

      if (hours > 0) {
        formattedDuration += `${hours} hours `;
      }

      formattedDuration += `${minutes} mins ${seconds} secs`;

      return formattedDuration.trim(); // Trim any extra spaces
    },
    getInquiryPossibleCompletionStatus(dateQuoted) {
      const now = new Date();
      const quotedDate = new Date(dateQuoted);

      // Calculate the difference in milliseconds
      const diffInMilliseconds = now - quotedDate;

      // Convert difference to hours
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      // Determine the status based on the difference in hours
      if (diffInHours < 120) { // less than 5 days (5 days = 120 hours)
        const hoursRemaining = Math.ceil(120 - diffInHours);
        return {
          label: 'Completed within',
          hoursRemaining: hoursRemaining,
          grade: 'A'
        }
      } else if (diffInHours >= 120 && diffInHours < 168) { // between 5 days (120 hours) and 7 days (168 hours)
        const hoursRemaining = Math.ceil(168 - diffInHours);
        return {
          label: 'Completed within',
          hoursRemaining: hoursRemaining,
          grade: 'B'
        }
      } else {
        return {
          label: 'AutoFailed.',
          hoursRemaining: 0,
          grade: 'C'
        }
      }
    },
    splitDays(startDate, endDate) {
      const start = moment(startDate);
      const end = moment(endDate);

      if (start.isAfter(end)) {
        return []; // If the start date is after the end date, return an empty array
      }

      const result = [];
      let current = moment(start);

      while (current.isBefore(end, 'day') || current.isSame(end, 'day')) {
        const dayOfWeek = current.isoWeekday(); // ISO weekday: 1 = Monday, 7 = Sunday

        // Skip Saturday (6) and Sunday (7)
        if (dayOfWeek !== 6 && dayOfWeek !== 7) {
          const endOfDay = moment(current).endOf('day');

          const totalSeconds =
              current.isSame(end, 'day')
                  ? end.diff(current, 'seconds') // Calculate seconds up to the end date
                  : endOfDay.diff(current, 'seconds'); // Calculate seconds up to the end of the day

          const hours = Math.floor(totalSeconds / 3600); // Convert seconds to hours
          const minutes = Math.floor((totalSeconds % 3600) / 60); // Get remaining minutes
          const seconds = totalSeconds % 60; // Get remaining seconds

          result.push({
            weekday: current.format('dddd'),
            hours,
            minutes,
            seconds,
          });
        }

        // Move to the start of the next day
        current = current.startOf('day').add(1, 'days');
      }

      return result;
    }
  },
  mounted() {
    this.getInquires()
  }
}
</script>

<template>
  <div class="table-responsive">
    <table class="table table-bordered table-nowrap">
      <thead>
      <tr>
        <th class="text-center" scope="col">#</th>
        <th scope="col">Inquiry Message</th>
        <th scope="col">Date Created</th>
        <th scope="col">Status</th>
        <th scope="col">
          <div class="d-flex align-items-center gap-2">
            <h6 class="mb-0 text-warning fw-medium">Pending</h6>
            <i class="mdi mdi-arrow-right"></i>
            <h6 class="mb-0 text-secondary fw-medium">Quoted</h6>
          </div>
        </th>
        <th scope="col">
          <div class="d-flex align-items-center gap-2">
            <h6 class="mb-0 text-secondary fw-medium">Quoted</h6>
            <i class="mdi mdi-arrow-right"></i>
            <div class="d-flex align-items-center">
              <h6 class="mb-0 text-success fw-medium">Success</h6>
              <i class="mdi mdi-slash-forward text-muted"></i>
              <h6 class="mb-0 text-danger fw-medium">Fail</h6>
            </div>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(inquiry, index) in inquires" :key="`inquiry_${inquiry}`">
        <tr class="align-middle">
          <th class="text-center" scope="row">{{ index + 1 }}</th>
          <td style="max-width: 75px;">
            <p class="mb-0 text-truncate pe-2"
               v-b-tooltip.hover :title="cleanText(inquiry.text)"
               v-html="cleanText(inquiry.text)"></p>
          </td>
          <td style="max-width: 150px;">
            <small class="d-block text-muted">{{ timeAgo(inquiry.created) }}</small>
            <span>{{ formatDate(inquiry.created) }}</span>
          </td>
          <td style="max-width: 150px;">
            <h6 class="mb-0 text-uppercase">
              <span v-if="inquiry.status === 'pending'"
                    class="badge me-1 bg-soft-warning text-warning me-2">PENDING</span>
              <span v-else-if="inquiry.status === 'quoted'" class="badge me-1 bg-soft-secondary text-secondary me-2">QUOTED</span>
              <span v-else-if="inquiry.status === 'success'" class="badge me-1 bg-soft-success text-success me-2">SUCCESS</span>
              <span v-else-if="inquiry.status === 'fail'" class="badge me-1 bg-soft-danger text-danger me-2">FAIL</span>
            </h6>
          </td>
          <td>
            <div v-if="inquiry.status === 'pending'" class="d-flex" v-b-tooltip.hover
                 :title="`Inquiry is still pending since ${formatDate(inquiry.created)}`">
              <div class="flex-shrink-0 avatar-xs">
                <div class="avatar-title rounded-circle material-shadow bg-soft-warning text-warning">
                  <i class="mdi mdi-clock-outline"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span class="badge me-1 bg-soft-warning text-warning">PENDING</span>
                </h6>
                <small class="mb-0 text-muted"
                >
                  Since {{ formatDate(inquiry.created) }}
                </small>
              </div>
            </div>
            <div v-else class="d-flex align-items-center">

              <div class="flex-shrink-0 avatar-xs">
                <div v-b-tooltip.hover
                     :title="`Response-Time KPI grade is '${inquiry.quote_grade}' because quoted in ${inquiry.pending_days.total_pending_hours} hours`"
                     class="avatar-title rounded material-shadow" :class="{
                  'bg-soft-success text-success': inquiry.quote_grade === 'A',
                  'bg-soft-warning text-warning': inquiry.quote_grade === 'B',
                  'bg-soft-danger text-danger': inquiry.quote_grade === 'C',
                }">
                  {{ inquiry.quote_grade }}
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span v-b-tooltip.hover
                        :title="`${inquiry.pending_days.items.length} business day(s) -> ${inquiry.pending_days.total_pending_hours} hours ${inquiry.pending_days.total_pending_minutes} minutes and ${inquiry.pending_days.total_pending_seconds} seconds. Time taken to update the inquiry status from 'pending' to 'quoted'`">
                    <template v-if="inquiry.pending_days.total_pending_hours > 0">
                      {{ inquiry.pending_days.total_pending_hours }}h
                    </template>
                    <template v-if="inquiry.pending_days.total_pending_minutes > 0">
                      {{ inquiry.pending_days.total_pending_minutes }}m
                    </template>
                    <template v-if="inquiry.pending_days.total_pending_seconds > 0">
                      {{ inquiry.pending_days.total_pending_seconds }}s
                    </template>
                  </span>
                </h6>
                <div class="d-flex flex-wrap gap-1">
                  <span
                      v-b-tooltip.hover
                      :title="`${item.weekday} -> ${item.hours} hours ${item.minutes} minutes and ${item.seconds} seconds`"
                      v-for="item in inquiry.pending_days.items"
                      :key="`inquiry_day_${item.weekday}`"
                      class="badge me-1 bg-soft-secondary text-secondary"
                  >
                      {{ item.weekday }}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div v-if="inquiry.status === 'pending'" class="d-flex" v-b-tooltip.hover
                 :title="`Inquiry is still pending since ${formatDate(inquiry.created)}`">
              <div class="flex-shrink-0 avatar-xs">
                <div class="avatar-title rounded-circle material-shadow bg-soft-warning text-warning">
                  <i class="mdi mdi-clock-outline"></i>
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-1 lh-base text-uppercase">
                  <span class="badge me-1 bg-soft-warning text-warning">PENDING</span>
                </h6>
                <small class="mb-0 text-muted"
                >
                  Since {{ formatDate(inquiry.created) }}
                </small>
              </div>
            </div>
            <div v-else-if="inquiry.status === 'quoted'">
              <div v-if="inquiry.possibleCompletion.grade === 'A'">
                <span class="d-block">Complete within <b>{{
                    inquiry.possibleCompletion.hoursRemaining
                  }} hours</b> </span>
                <span>and get <span class="badge badge-soft-success text-success fw-medium">A</span></span> from
                Follow-up KPI
              </div>
              <div v-if="inquiry.possibleCompletion.grade === 'B'">
                <span class="d-block">Complete within <b>{{
                    inquiry.possibleCompletion.hoursRemaining
                  }} hours</b> </span>
                <span>and get <span class="badge badge-soft-warning text-warning fw-medium">B</span></span> from
                Follow-up KPI
              </div>
              <span v-else-if="inquiry.possibleCompletion.grade === 'C'">
                AutoFailed
              </span>
            </div>
            <div v-else-if="['success', 'fail'].includes(inquiry.status)" class="d-flex align-items-center">
              <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                <div v-b-tooltip.hover
                     :title="`Follow-up KPI grade is '${inquiry.completion_grade}' because completed in ${inquiry.completion_days.total_hours} hours`"
                     class="avatar-title rounded material-shadow" :class="{
                  'bg-soft-success text-success': inquiry.completion_grade === 'A',
                  'bg-soft-warning text-warning': inquiry.completion_grade === 'B',
                  'bg-soft-danger text-danger': inquiry.completion_grade === 'C',
                }">
                  {{ inquiry.completion_grade }}
                </div>
              </div>
              <div class="flex-grow-1 ms-3">
                <h6 class="mb-2 lh-base text-uppercase">
                  <span v-b-tooltip.hover
                        :title="`Time taken to update the inquiry status from 'quoted' to '${inquiry.status}'`">
                    <template v-if="inquiry.completion_days.total_hours > 0">
                      {{ inquiry.completion_days.total_hours }}h
                    </template>
                    <template v-if="inquiry.completion_days.total_minutes > 0">
                      {{ inquiry.completion_days.total_minutes }}m
                    </template>
                    <template v-if="inquiry.completion_days.total_seconds > 0">
                      {{ inquiry.completion_days.total_seconds }}s
                    </template>
                  </span>
                </h6>
                <div class="d-flex flex-wrap gap-1" style="max-width: 300px">
                  <span
                      v-b-tooltip.hover
                      :title="`${item.weekday} -> ${item.hours} hours ${item.minutes} minutes and ${item.seconds} seconds`"
                      v-for="item in inquiry.completion_days.items"
                      :key="`inquiry_day_${item.weekday}`"
                      class="badge me-1 bg-soft-secondary text-secondary"
                  >
                      {{ item.weekday }}
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
      <tr v-if="inquires.length === 0">
        <th colspan="5">
          <span>No data</span>
          <span v-if="start_date"> : {{ start_date }}</span>
          <span v-if="end_date"> - {{ end_date }}</span>
        </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>