<script>
import PageHeader from "@/components/page-header.vue";
import InquiresList from "@/views/pages/inquires/components/List.vue";
import ManagerKPIScore from "@/views/pages/inquires/components/ManagerKPIScore.vue";
import ManagerInquiryByStatus from "@/views/pages/inquires/components/ManagerInquiryByStatus.vue";
import ManagerAverageResponseTime from "@/views/pages/inquires/components/ManagerAverageTime.vue";
import userStore from '@/state/store'
import axios from "axios";
import Months from "@/core/Months";
import moment from "moment";
import {INQUIRY_KPI} from "@/core/KPI";

export default {
  name: "Index",
  components: {
    PageHeader,
    InquiresList,
    ManagerKPIScore,
    ManagerInquiryByStatus,
    ManagerAverageResponseTime,
  },
  data() {
    return {
      title: "Inquires",
      breadcrumbs: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Inquires",
          active: true
        }
      ],

      statistics: [],
      is_loading: true,
      is_loading_kpi_data: true,

      dates: {
        start: null,
        end: null,
        options: Months,
        option_value: '',
      },

      all_time_inquires_statistics: [],
      INQUIRY_KPI: INQUIRY_KPI
    };
  },
  methods: {
    async getStatistics() {
      try {
        this.is_loading = true
        let response = await axios.get('/inquiry/statistic/kpi/', {
          params: {
            date_from: this.dates.start,
            date_to: this.dates.end,
          }
        })
        this.statistics = response.data || []
        setTimeout(() => {
          this.is_loading = false
        }, 500)
      } catch {
        setTimeout(() => {
          this.is_loading = false
        }, 500)
        alert("eror")
      }
    },
    async onTableUpdated() {
      await this.getStatistics()
      await this.$refs.managerInquiryByStatus.getStatistics()
    },

    async setDate(option) {
      if (this.dates.option_value === option || option === this.last_month_option) return
      this.dates.option_value = option
    }
  },
  computed: {
    user() {
      return userStore.state.user
    },
    statuses() {
      if (this.is_loading) return []
      try {
        let manager = this.statistics.find(i => i.manager.id === this.user.id)
        if (manager) {
          return (manager.statuses || []).map(i => {
            let item = {...i}

            if (item.status.toLowerCase() === 'pending') {
              item.kpi_inquiries = []
            } else if (item.status.toLowerCase() === 'quoted') {
              item.kpi_inquiries = this.statistics[0].response_time || []
            } else if (item.status.toLowerCase() === 'success') {
              item.kpi_inquiries = this.statistics[0].conversion || []
            } else if (item.status.toLowerCase() === 'fail') {
              item.kpi_inquiries = this.statistics[0].completion || []
            }

            return item
          })
        } else {
          return []
        }
      } catch {
        return []
      }
    },
    pending_inquires_count() {
      try {
        let pending = this.statuses.find(i => i.status === 'Pending')
        if (pending) {
          return pending.count
        } else {
          return 0
        }
      } catch {
        return 0
      }
    },
    response_time_details() {
      if (this.is_loading) {
        return []
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          if (manager) {
            return Object.keys(manager.response_time.details).flatMap(grade =>
                manager.response_time.details[grade].inquiries
            );
          } else {
            return []
          }
        } catch {
          return []
        }
      }
    },
    completion_details() {
      if (this.is_loading) {
        return []
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          if (manager) {
            return Object.keys(manager.completion.details).flatMap(grade =>
                manager.completion.details[grade].inquiries
            );
          } else {
            return []
          }
        } catch {
          return []
        }
      }
    },
    average_response_time() {
      let response_times = this.response_time_details.map(i => i.quote_time);

      const totalSeconds = response_times.reduce((sum, duration) => sum + parseFloat(duration), 0);

      const averageSeconds = totalSeconds / (response_times.length || 1);

      return {
        days: Math.floor(averageSeconds / 86400),
        hours: Math.floor((averageSeconds % 86400) / 3600),
        minutes: Math.floor((averageSeconds % 3600) / 60),
        seconds: Math.floor(averageSeconds % 60),
      };
    },
    average_completion_time() {
      let response_times = this.completion_details.map(i => i.resolution_time);

      const totalSeconds = response_times.reduce((sum, duration) => sum + parseFloat(duration), 0);

      const averageSeconds = totalSeconds / (response_times.length || 1);

      return {
        days: Math.floor(averageSeconds / 86400),
        hours: Math.floor((averageSeconds % 86400) / 3600),
        minutes: Math.floor((averageSeconds % 3600) / 60),
        seconds: Math.floor(averageSeconds % 60),
      };
    },
    overall_kpi_percentage() {
      if (this.is_loading) return 0
      try {
        let manager = this.statistics.find(i => i.manager.id === this.user.id)
        if (manager) {
          let response_time = manager.response_time.target
          let completion = manager.completion.target
          let conversion = manager.conversion.target
          let leads = manager.leads.target
          return (
              response_time * (this.INQUIRY_KPI.response_time.weight / 100) +
              completion * (this.INQUIRY_KPI.completion.weight / 100) +
              conversion * (this.INQUIRY_KPI.conversion.weight / 100) +
              leads * (this.INQUIRY_KPI.leads.weight / 100)
          ).toFixed(1)
        } else {
          return 0
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    kpi_data() {
      if (this.is_loading) {
        return {}
      } else {
        try {
          let manager = this.statistics.find(i => i.manager.id === this.user.id)
          return manager ? manager : null
        } catch {
          return null
        }
      }
    },
    last_month_option() {
      let month = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

      let monthIndex = moment(month).month();
      return `month_${monthIndex + 1}`
    }
  },
  async mounted() {
    let currentMonth = moment().month() + 1;
    await this.setDate(`month_${currentMonth}`);
  },
  watch: {
    'dates.option_value': {
      async handler() {
        let option = this.dates.option_value

        if (option.startsWith('month_')) {
          let monthIndex = parseInt(option.split('month_')[1]) - 1;
          this.dates.start = moment().month(monthIndex).startOf('month').format('YYYY-MM-DD');
          this.dates.end = moment().month(monthIndex).endOf('month').format('YYYY-MM-DD');
          await this.$router.push({
            query: {
              ...this.$route.query,
              created: monthIndex + 1
            }
          })
        } else if (option === 'last_7_days') {
          this.dates.start = moment().subtract(6, 'days').format('YYYY-MM-DD');
          this.dates.end = moment().format('YYYY-MM-DD');
        } else if (option === 'last_month') {
          this.dates.start = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
          this.dates.end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');

          let monthIndex = moment(this.dates.start).month();

          await this.$router.push({
            query: {
              ...this.$route.query,
              created: monthIndex + 1
            }
          })
        } else if (option === 'last_3_months') {
          this.dates.start = moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD');
          this.dates.end = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        } else if (option === 'all_time') {
          this.dates.start = moment('2022-01-01').format('YYYY-MM-DD');
          this.dates.end = moment().format('YYYY-MM-DD');
          let queries = {
            ...this.$route.query,
          }
          delete queries.created
          await this.$router.push({
            query: queries
          })
        }

        await this.getStatistics()
      }
    },
    "$route.query.created": {
      async handler(newMonthIndex) {
        if (newMonthIndex) {
          this.dates.start = moment().month(newMonthIndex - 1).startOf('month').format('YYYY-MM-DD');
          this.dates.end = moment().month(newMonthIndex - 1).endOf('month').format('YYYY-MM-DD');

          await this.setDate(`month_${newMonthIndex}`)
        }
      }
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="breadcrumbs"/>
  <b-row gutter-y="4">
    <b-col sm="12">
      <ManagerKPIScore
          ref="managerKPIScore"
          :user_kpi_data="kpi_data"
          :loading="is_loading"
          :pending_inquires_count="pending_inquires_count"
          :overall_kpi_percentage="overall_kpi_percentage"
          :start_date="dates.start"
          :end_date="dates.end"
      >
        <template v-slot:date_filters>
          <div class="d-flex align-items-center gap-2">
            <b-button @click="setDate(`last_7_days`)" type="button"
                      :variant="dates.option_value === 'last_7_days' ? 'primary' : 'soft-primary'"
                      size="sm">Last 7 days
            </b-button>
            <b-button @click="setDate(`last_month`)" type="button"
                      :variant="dates.option_value === 'last_month' || dates.option_value === last_month_option ? 'primary' : 'soft-primary'"
                      size="sm">Last month
            </b-button>
            <b-button @click="setDate(`last_3_months`)" type="button"
                      :variant="dates.option_value === 'last_3_months' ? 'primary' : 'soft-primary'" size="sm">Last 3
              months
            </b-button>

            <div>
              <select v-model="dates.option_value"
                      class="form-select form-select-sm cursor-pointer"
                      style="padding-top: 5px; padding-bottom: 5px"
              >
                <option class="bg-primary">Months</option>
                <option :value="`month_${option.id}`"
                        v-for="option in dates.options" :key="`date_option_${option.id}`">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <b-button @click="setDate(`all_time`)" type="button"
                      :variant="dates.option_value === 'all_time' ? 'primary' : 'soft-primary'" size="sm">
              All Time
            </b-button>
          </div>
        </template>
      </ManagerKPIScore>
    </b-col>
    <b-col xxl="9">
      <div class="mb-3">
        <InquiresList @update-statistics="onTableUpdated()"/>
      </div>
    </b-col>
    <b-col xxl="3">
      <div>
        <ManagerInquiryByStatus
            ref="managerInquiryByStatus"
            :date_start="dates.start"
            :date_to="dates.end"
        />
        <ManagerAverageResponseTime
            ref="managerAverageResponseTime"
            :loading="is_loading"
            label="Average Response Time"
            description="Represents the average time taken to provide quotes for pending inquiries, showcasing how quickly these inquiries are being quoted"
            :days="average_response_time.days"
            :hours="average_response_time.hours"
            :minutes="average_response_time.minutes"
            :seconds="average_response_time.seconds"
        />
        <ManagerAverageResponseTime
            ref="managerAverageResponseTime"
            :loading="is_loading"
            label="Average Completion Time"
            description="Represents the average time taken to update the status of inquiries from 'quoted' to either 'success' or 'fail.' It measures how quickly inquiries progress through the quoting process to their final outcomes."
            :days="average_completion_time.days"
            :hours="average_completion_time.hours"
            :minutes="average_completion_time.minutes"
            :seconds="average_completion_time.seconds"
        />
      </div>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>